const GLOBAL = {
  ADMIN: "admin",
  USER: "user",
  DRAWER_WIDTH: 240,
};

export const ROLES = {
  ADMIN: "admin",
  USER: "user",
};

export const LAYERS = {
  STREETV11: "mapbox://styles/mapbox/streets-v11", //
  SETALLITEV9: "mapbox://styles/mapbox/satellite-v9", //setallite-v9
  // SETALLITEV9: "mapbox://styles/saadasad/clkccs4od000x01pobsfwarpg", //custom
  SETALLITEV12: "mapbox://styles/mapbox/satellite-streets-v12",
  DARK1: "mapbox://styles/saadasad/cll3uggz800gc01p8eezpb4a2",
  ILLUMINATED1: "mapbox://styles/saadasad/cll3ugjb200gr01pmawnlfp3p",
  ILLUMINATED2: "mapbox://styles/saadasad/cll3uglxx00h101qp5ipleo0p",
  ILLUMINATED4: "mapbox://styles/mapbox/satellite-streets-v11",
  // ILLUMINATED3: "mapbox://styles/saadasad/cll3ugof900gr01qs1iicgym5",
};
export default GLOBAL;
