import "../../Dashboard/styles.css";
import { useState } from "react";
import { toast } from "react-toastify";
import { updatePolygonVisibility } from "utils/updatePolygonVisibility";
const TogglePopup = ({
  role,
  id,
  clientVisibility,
  currentSegments,
  sourceData,
  onHandleChange,
  setToggleChanged,
  onClose,
}) => {
  const [segmentVisibility, setSegmentVisibility] = useState(
    clientVisibility ? clientVisibility : false
  );

  function handleUpdateDataLocally(id, updateValue) {
    const updatedArray = sourceData.map((object) => {
      if (object.id === id) {
        if (currentSegments.length === 0) {
          return { ...object, clientVisibility: updateValue };
        } else if (currentSegments.length > 0 && !updateValue) {
          return null;
        }
      }
      return object;
    });
    const filteredUpdatedArray = updatedArray.filter(Boolean);
    // onClose(id);
    onHandleChange(role, filteredUpdatedArray);
    setToggleChanged(true);
    // setFetchedData(filteredUpdatedArray);
  }

  const handleToggleChange = async (e) => {
    const { checked } = e.target;
    setSegmentVisibility(checked);
    try {
      await updatePolygonVisibility(id, { clientVisibility: checked });
      handleUpdateDataLocally(id, checked);
      // await getUpdatedData(user);
    } catch (err) {
      console.log("erorr occured:", err);
      toast.error("An error occurred while updating the document.");
      setSegmentVisibility(!checked);
    }
  };
  return (
    <>
      {role === "admin" && (
        <>
          <div className="d-flex" style={{ padding: "0.5rem" }}>
            <p
              style={{ marginRight: "0.5rem", marginBottom: 0, color: "white" }}
            >
              Client Visibility
            </p>
            <label className="switch">
              <input
                type="checkbox"
                id="toggle-switch"
                checked={segmentVisibility}
                onChange={handleToggleChange}
              />
              <span className="slider1 round"></span>
            </label>
          </div>
        </>
      )}
    </>
  );
};

export default TogglePopup;
