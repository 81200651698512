import * as React from "react";
import "./MarkerDescBar.css";

const MarkerDescBar = (props) => {
  const location = JSON.parse(props.clickedMarker?.location);
  const tiltData = JSON.parse(props.clickedMarker?.tilt);
  return (
    <div
      //   id="slider"
      style={{
        position: "absolute",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        minWidth: "500px",
        backgroundColor: "black",
        color: "white",
        padding: "0.5vw",
        border: "0vw",
        marginLeft: "14%",
        // marginBottom: "5%",
        paddingLeft:'2vh',
        paddingRight:'2vh',
        flexWrap: "wrap",
        // marginTop: "200px",
        borderRadius: "25px",
        transition: "margin 1000ms ease-in-out",
        bottom:'3%'
      }}
    >
      
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          //   minWidth: "1vw",
        }}
      >
        <div style={{ fontFamily: "monospace" }}>Lat: </div>
        <div>{location.latitude}</div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          minWidth: "4vw",
        }}
      >
        <div style={{ fontFamily: "monospace" }}> Long: </div>
        <div>{location.longitude}</div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          minWidth: "4vw",
        }}
      >
        <div style={{ fontFamily: "monospace" }}>Tilt :</div>
        <div>
          X: {tiltData?.x} , Y: {tiltData?.y}{" "}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          // minWidth: "1vw",
        }}
      >
        <div className="heading-icon">
          {/* <NorthIcon size="small"></NorthIcon> */}
        </div>
        <div style={{ marginLeft: "3px" }}>
          : {props.clickedMarker?.heading}
        </div>
      </div>
    </div>
  );
};
export default MarkerDescBar;
