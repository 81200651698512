import { Fragment } from "react";
import CarouselComponent from "components/UIComponent/Carousel";
import "./Overlay.css";
import MarkerDesc from "components/UIComponent/MarkerDesc";
import OverlaySideBar from "components/UIComponent/OverlaySideBar";
import TooltipBar from "pages/Dashboard/popups/TooltipBar";
import MarkerDescBar from "components/UIComponent/MarkerDescBar";

export function Overlay(props) {
  console.log(props);
  return (
    <Fragment>
      <div className="marker-overlay">
        <div className="upper-container">
          <div className="overlay-popup">
            <TooltipBar
              pop={true}
              setTriggerOverlay={props.setTriggerOverlay}
              clickedMarker={props.clickedMarker}
              removeSelectedMarker={props.removeSelectedMarker}
              setCloseOverlay={props.setCloseOverlay}
              setIsFieldEdited={props.setIsFieldEdited}
              role={props.role}
            ></TooltipBar>
          </div>
        </div>

        <CarouselComponent
          clickedMarker={props.clickedMarker}
          triggerOverlay={props.triggerOverlay}
          setImgGallery={props.setImgGallery}
          imgGallery={props.imgGallery}
        ></CarouselComponent>

        <div className="bottom-container">
          <div>
            <MarkerDesc clickedMarker={props.clickedMarker}></MarkerDesc>
          </div>
        </div>

        <OverlaySideBar clickedMarker={props.clickedMarker}></OverlaySideBar>

        <MarkerDescBar clickedMarker={props.clickedMarker}></MarkerDescBar>
      </div>
    </Fragment>
  );
}

export default Overlay;
