import { collection, getDocs, orderBy, query,where } from "@firebase/firestore";
import { firestore } from "configs/firebase";


// Function for getting data from Firebase
export const fetchDeviceInfo = async (role) => {
  let admin_query;
  try {
    const document_ref = collection(firestore, "DeviceInfo");
    admin_query = query(document_ref);
    let querySnapshot; 
    querySnapshot = await getDocs(admin_query); 
    let fetchedData=[];
    if (!querySnapshot.empty) {
      querySnapshot.forEach(doc => {
        fetchedData.push({id:doc.id, ...doc.data()});
      })
    }
    else {
      console.log("No data found in Firestore");
    }
    return fetchedData;
  }
  catch (err) {
      console.log("Error found while fetching data: ", err);
      throw new Error(err);

    // return err;
  }
}
