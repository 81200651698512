import { ThemeProvider } from "react-bootstrap";
import { BrowserRouter } from "react-router-dom";
import "fonts/fonts.css";
import "./App.css";
import MainRoutes from "./routes";

function App() {
  return (
    <div className="App">
      <ThemeProvider
        breakpoints={["xxxl", "xxl", "xl", "lg", "md", "sm", "xs", "xxs"]}
        minBreakpoint="xxs"
      >
        <BrowserRouter>
          <MainRoutes />
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
