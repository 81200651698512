import "bootstrap/dist/css/bootstrap.min.css";
import "mapbox-gl/dist/mapbox-gl.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./fonts/static/Montserrat-Regular.ttf";
import App from "./App";
import "./index.css";
import { persisStore, store } from "./redux/Store";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(

  <Provider store={store}>
   <PersistGate loading={null} persistor={persisStore}>
         <App />
      <ToastContainer />
       </PersistGate>
     </Provider>
  // <React.StrictMode>
  //   <Provider store={store}>
  //     <PersistGate loading={null} persistor={persisStore}>
  //       <App />
  //       <ToastContainer />
  //     </PersistGate>
  //   </Provider>
  // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
