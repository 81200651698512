import React from "react";
import { useState } from "react";
import { DateRangePicker, defaultStaticRanges } from "react-date-range";
import moment from "moment";
import { isSameDay, parseISO } from "date-fns";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./headerStyles.css";

function Header(props) {
  const [modalShow, setModalShow] = useState(false);
  const [localstartDate, setLocalStartDate] = useState(props.startDate);
  const [localendDate, setLocalEndDate] = useState(props.endDate);
  const [currentYear] = useState(new Date().getFullYear());
  const [currentState, setCurrentState] = useState([
    {
      startDate: parseISO(props.startDate),
      endDate: parseISO(props.endDate),
      key: "selection",
    },
  ]);
  // Toggling Captured Mode based on Key Shortcuts
  // useEffect(() => {
  //   const handleKeyDown = (event) => {
  //     // Check for the desired keyboard shortcut keys and update the state accordingly
  //     if (event.key === "W") {
  //       props.setSelectedImageMode("waypoint");
  //     } else if (event.key === "P") {
  //       props.setSelectedImageMode("panaroma");
  //     } else if (event.key === "S") {
  //       props.setSelectedImageMode("snapshot");
  //     } else if (event.key === "A") {
  //       props.setSelectedImageMode("");
  //     }
  //   };
  //   // Add the event listener
  //   document.addEventListener("keydown", handleKeyDown);
  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     document.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, []);

  // Managing When user changing Dates
  const handleChangeDateRange = (item) => {
    setCurrentState([item.selection]);
    const formattedStartDate = moment(item.selection.startDate).format(
      "YYYY-MM-DD"
    );
    const formattedEndDate = moment(item.selection.endDate).format(
      "YYYY-MM-DD"
    );
    setLocalStartDate(formattedStartDate);
    setLocalEndDate(formattedEndDate);
  };

  // Handle Saving DateRange
  const handleSumbitDateRange = () => {
    props.setStartDate(localstartDate);
    props.setEndDate(localendDate);
    setModalShow(false);
  };

  return (
    <div className="header">
      <div onClick={() => setModalShow(true)}></div>
      {props.role === "admin" && (
        <>
          <Form.Control
            onClick={() => setModalShow(true)}
            type="text"
            readOnly
            value={`Date Range : ${
              props.startDate && moment(props.startDate).format("DD-MM-YYYY")
            } - ${props.endDate && moment(props.endDate).format("DD-MM-YYYY")}`}
            className="date-input"
          />
          <Form.Select
            onChange={(e) => props.setSelectedImageMode(e.target.value)}
            className="mode-input"
            value={props.selectedImageMode}
          >
            <option
              value=""
              style={{ fontFamily: "Montserrat", fontSize: "1.1vw" }}
            >
              All Modes
            </option>
            <option
              value="waypoint"
              style={{ fontFamily: "Montserrat", fontSize: "1.1vw" }}
            >
              Waypoint
            </option>
            <option
              value="snapshot"
              style={{ fontFamily: "Montserrat", fontSize: "1.1vw" }}
            >
              Snapshot
            </option>
            <option
              value="panorama"
              style={{ fontFamily: "Montserrat", fontSize: "1.1vw" }}
            >
              Panorama
            </option>
          </Form.Select>
          <Form.Select
            onChange={(event) => {
              props.setCurrentMarkers(event.target.value);
            }}
            className="mode-input"
            value={props.currentMarkers}
          >
            <option
              value=""
              style={{ fontFamily: "Montserrat", fontSize: "1.1vw" }}
            >
              All Markers
            </option>
            <option
              value="client-markers"
              style={{ fontFamily: "Montserrat", fontSize: "1.1vw" }}
            >
              Client Markers
            </option>
          </Form.Select>
          <Form.Select
            onChange={(event) => {
              props.setCurrentSegments(event.target.value);
            }}
            className="mode-input2"
            value={props.currentSegments}
          >
            <option
              value=""
              style={{ fontFamily: "Montserrat", fontSize: "1.1vw" }}
            >
              All Segments
            </option>
            <option
              value="client-segments"
              style={{ fontFamily: "Montserrat", fontSize: "1.1vw" }}
            >
              Client Segments
            </option>
          </Form.Select>
          <Form.Select
            onChange={(event) => {
              props.setImageFilterMarkers(event.target.value);
            }}
            className="mode-input3"
            value={props.imageFilterMarkers}
          >
            <option
              value="images"
              style={{ fontFamily: "Montserrat", fontSize: "1.1vw" }}
            >
              Markers with Images
            </option>
            <option
              value="no-images"
              style={{ fontFamily: "Montserrat", fontSize: "1.1vw" }}
            >
              Markers without Images
            </option>
            <option
              value="all"
              style={{ fontFamily: "Montserrat", fontSize: "1.1vw" }}
            >
             All Markers
            </option>
          </Form.Select>
        </>
      )}

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <h4>Select Date Range</h4>
          {modalShow && (
            <>
              <div className="row mb-20">
                <div className="col-12 ">
                  <DateRangePicker
                    className="d-flex flex-wrap"
                    showPreview={false}
                    inputRanges={[]}
                    onChange={handleChangeDateRange}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={1}
                    ranges={currentState}
                    direction="horizontal"
                    staticRanges={[
                      ...defaultStaticRanges,
                      {
                        label: "This Year",
                        range: () => ({
                          startDate: new Date(currentYear, 0, 1),
                          endDate: new Date(),
                        }),
                        isSelected(range) {
                          const definedRange = this.range();
                          return (
                            isSameDay(
                              range.startDate,
                              definedRange.startDate
                            ) && isSameDay(range.endDate, definedRange.endDate)
                          );
                        },
                      },
                      {
                        label: "All Time",
                        range: () => ({
                          startDate: new Date("2022-01-01"),
                          endDate: new Date(),
                        }),
                        isSelected(range) {
                          const definedRange = this.range();
                          return (
                            isSameDay(
                              range.startDate,
                              definedRange.startDate
                            ) && isSameDay(range.endDate, definedRange.endDate)
                          );
                        },
                      },
                    ]}
                  />
                </div>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            color="primary"
            className="text-white mb-10 mr-3"
            onClick={handleSumbitDateRange}
          >
            Save Range
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Header;
