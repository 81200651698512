import { firestore } from "configs/firebase";
import { writeBatch } from "@firebase/firestore";
import { doc } from "firebase/firestore";
export const updateBulkMarkerData = async (data) => {
    try {
        const batch = writeBatch(firestore);
        Object.keys(data).forEach(key=>{
            const docRef = doc(firestore,"capturedImagesDetails", key);
             batch.update(docRef, data[key]);
        })
    await batch.commit();
      

    } catch (err) {
        console.error('Error updating documents:', err);
        throw new Error(err);
    }
};
