import moment from "moment";
import "../../Dashboard/styles.css";
import React from "react";
import { useState, useEffect } from "react";
import { updateMarkerData } from "utils/updateMarkerData";
import { toast } from "react-toastify";
import { ROLES } from "constants/global";
import { updateCropData } from "utils/updateFieldsData";
import { Input } from "@mui/material";
import { Button } from "@mui/material";
import { updateBulkMarkerData } from "utils/updateBulkMarkerData";

const TooltipBar = ({
  setCurrentElement,
  setIsFormEdited,
  markerData,
  role,
  currentMarkers,
  fetchedData,
  setFetchedData,
  setTriggerOverlay,
  pop,
  triggerOverlay,
  insideMap,
  clickedMarker,
  setIsPopupDragging,
  removeSelectedMarker,
  setImgGallery,
  setIsFieldEdited,
  getVisibiltyToggle,
  setClickedMarker,
  // setMarkerVisibility,
  setCloseOverlay, // state for checking if overlay screen has just closed and not add selected marker style in that case
  overrideData
  // setAllowDrag,
}) => {
  const [editFieldRef, setEditFieldref] = useState(false);
  const [newFieldRef, setnewFieldRef] = useState("");
  const [editCropType, setEditCropType] = useState(false);
  const [newCropType, setnewCropType] = useState("");
  const [editPlantedDate, setEditPlantedDate] = useState(false);
  const [newPlantedDate, setnewPlantedDate] = useState(new Date());
  const [thumbnail, setThumbnail] = useState("");
  console.log(currentMarkers)
  const [plusicon, setPlusicon] = useState(pop || false);
  const [markerVisibility, setMarkerVisibility] = useState(
    (markerData?.clientVisibility || markerData?.clientVisibility===undefined) ? true : false
  );

  const [formErrors, setFormErrors] = useState();
  


  let thumbnailClass =
    markerData?.capturedImageMode === "panorama"
      ? "thumbnail-landscape-bar"
      : markerData?.capturedImageMode === "waypoint"
      ? "thumbnail-waypoint-bar"
      : "thumbnail-portrait-bar";

  let thumbnailClassTopBar =
    clickedMarker?.capturedImageMode === "panorama"
      ? "thumbnail-landscape"
      : clickedMarker?.capturedImageMode === "waypoint"
      ? "thumbnail-waypoint"
      : "thumbnail-portrait";

      const handleThumbnail = () => {
        if (overrideData.length > 1) {
          const imageTypes = { snapshot: 0, waypoint: 0, panorama: 0 };
          overrideData.forEach((item) => (imageTypes[item.capturedImageMode] += 1));
          let maxValue = Object.keys(imageTypes).reduce((a, b) =>
            imageTypes[a] > imageTypes[b] ? a : b
          );
          setThumbnail(
            maxValue === "panorama"
              ? "thumbnail-landscape-bar"
              : maxValue === "waypoint"
              ? "thumbnail-waypoint-bar"
              : "thumbnail-portrait-bar"
          );
        } else {
          setThumbnail(
            markerData?.capturedImageMode === "panorama"
              ? "thumbnail-landscape-bar"
              : markerData?.capturedImageMode === "waypoint"
              ? "thumbnail-waypoint-bar"
              : "thumbnail-portrait-bar"
          );
        }
      };
    useEffect(() => handleThumbnail(), [thumbnail]);

  const validate = (values) => {
    let errors = "";
    const regex = /^[A-Za-z]+$/;
    if (!values) {
      errors = "Cannot be blank";
    } else if (!regex.test(values)) {
      errors = "Invalid Field Value.";
    }
    console.log(errors);
    return errors;
  };
  const validateDate = (date) => {
    let errors = "";
    var capturedDate = markerData?.dateTime;
    console.log(capturedDate);
    console.log(new Date(date));

    if (new Date(date) > new Date(capturedDate)) {
      errors = "Invalid date Field.";
    }
    console.log(errors);
    return errors;
  };

  const handleClose = () => {
    setEditFieldref(false);
    setEditCropType(false);
    setEditPlantedDate(false);
  };

  const handleUpdate = async (
    markerDataFieldValue,
    newEditableField,
    field,
    fieldArr,
    err
  ) => {
    console.log(markerData?.fieldReference);
    try {
      setFormErrors(validate(newEditableField))
      if (field === "plantedDate") {
        setFormErrors(validateDate(newEditableField));
      } else if (field === "cropType" || field === "fieldReference") {
        setFormErrors(validate(newEditableField));
      }
      if(Object.keys(err).length === 0){
        await updateCropData(
          markerData?.id,
          markerDataFieldValue ? markerDataFieldValue : "null",
          newEditableField,
          field,
          fieldArr
        );

      }
      
    } catch (err) {
      console.log("erorr occured:", err);
      //   toast.error("An error occurred while updating the document.");
    }
  };
  const handleToggleChange = async (e) => {
    // markerData.plantedDate=newPlantedDate

    const { checked } = e.target;
    setMarkerVisibility(checked);
    if (triggerOverlay === true) {
    }
    try {
      if (triggerOverlay === false) {
        let overridingMarkers={}
        if(overrideData.length>1)
        {
          overrideData.forEach(async(data)=>{
            overridingMarkers[data.id]={ clientVisibility: checked }
            if (
              data?.capturedImageMode === "panorama" &&
              data.panoramaImages
            ) {
              if (data.panoramaImages[0]) {
                await updateMarkerData(data.panoramaImages[0].id, {
                  clientVisibility: checked,
                });
              }
              if (data.panoramaImages[1]) {
                await updateMarkerData(data.panoramaImages[1].id, {
                  clientVisibility: checked,
                });
              }
              if (data.panoramaImages[2]) {
                await updateMarkerData(data.panoramaImages[2].id, {
                  clientVisibility: checked,
                });
              }
            } 
          })
          updateBulkMarkerData(overridingMarkers)
        }
       
        else{
        await updateMarkerData(markerData?.id, { clientVisibility: checked });
        getVisibiltyToggle(markerData,markerVisibility,{ clientVisibility: checked })
        // if(markerData?.capturedImageMode ==== 'panorama'){
        if (
          markerData?.capturedImageMode === "panorama" &&
          markerData.panoramaImages
        ) {
          if (markerData.panoramaImages[0]) {
            await updateMarkerData(markerData.panoramaImages[0].id, {
              clientVisibility: checked,
            });
          }
          if (markerData.panoramaImages[1]) {
            await updateMarkerData(markerData.panoramaImages[1].id, {
              clientVisibility: checked,
            });
          }
          if (markerData.panoramaImages[2]) {
            await updateMarkerData(markerData.panoramaImages[2].id, {
              clientVisibility: checked,
            });
          }
        } 
        }
      }
    } catch (err) {
      console.log(err);
      toast.error("An error occurred while updating the document.");
      setMarkerVisibility(!checked);
    }
  };

  // Function to handle flow wheter image overlay or normal overlay
  const plusiconclick = (event) => {
    console.log("-->",markerData);
  
    console.log("icons cliked here");
    if (role === ROLES.ADMIN) {
      // setTriggerOverlay(true);
      setImgGallery(true);
    } else if (role === ROLES.USER) {
      setImgGallery(true);
    }
    //setTriggerOverlay(true);
    // setPlusicon(true);
    //setImgGallery(true);
  };

  const minusiconclick = (event) => {
    setTriggerOverlay(false);
    removeSelectedMarker();
    setCloseOverlay(true);
    setPlusicon(false);
  };

  // const dragIcon = () => {
  //   setAllowDrag(true);
  // };
  // const dragIconfalse = () => {
  //   setAllowDrag(false);
  // };

  const handleComponentMouseDown = () => {
    // console.log("this worked");
    setIsPopupDragging(true);
  };

  const handleComponentMouseUp = () => {
    // console.log("this also worked");
    setIsPopupDragging(false);
  };
  const [width, setWidth] = useState(window.innerWidth);
  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });
  // console.log(window.innerWidth);

  return (
    <>
      <div>
        {plusicon ? (
          <div
            id="slider"
            className="slide-in"
            style={{
              position: "absolute",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              minWidth: "496px",
              backgroundColor: "black",
              color: "white",
              padding: "4px",
              borderRadius: "25px",
              zIndex: "999",
              height: "32px",
              marginLeft: "4.5%",
              pointerEvents:"none",
              cursor:"none",
              userSelect: "none",
            }}
        
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              <div className={thumbnail}></div>
            </div>
            <div
              style={{
                display: "flex",
                flex: "none",
                justifyContent: "space-between",
                alignItems: "center",
                fontFamily: "Montserrat-Bold",
                fontSize: "0.7vw",
                marginLeft: "5px",
                marginRight: "5px",
              }}
            >
              <div>
              {overrideData?.length > 1 ? (
                  <div>{overrideData?.length}</div>
                ) : clickedMarker?.cropType ? (
                  <div>{clickedMarker?.cropType}</div>
                ) : (
                  <div>N/A</div>
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                minWidth: "1px",
              }}
            >
              {" "}
              <div className="calender-icon"></div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                minWidth: "4px",
                marginLeft: "5px",
                marginRight: "5px",
              }}
            >
              <div
                style={{
                  fontFamily: "Montserrat-Bold",
                  fontSize: "0.7vw",
                  marginLeft: "5px",
                  marginRight: "5px",
                }}
              >
                {clickedMarker?.plantedDate
                  ? moment(clickedMarker?.plantedDate).format("YYYY-MM-DD")
                  : "N/A"}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                minWidth: "1px",
              }}
            >
              <div className="mobile-icon"></div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                minWidth: "4px",
                marginLeft: "5px",
                marginRight: "5px",
              }}
            >
              <div
                style={{
                  fontFamily: "Montserrat",
                  marginLeft: "5px",
                  marginRight: "5px",
                  fontSize: "0.7vw",
                }}
              >
                {clickedMarker?.dateTime
                  ? moment(clickedMarker?.dateTime).format("YYYY-MM-DD")
                  : "N/A"}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontFamily: "Montserrat",
                fontSize: "0.7vw",
              }}
            >
                {overrideData?.length > 1
                ? "Cluster"
                : clickedMarker?.fieldReference
                ? clickedMarker?.fieldReference
                : "N/A"}
            </div>
            <>
              {role === ROLES.ADMIN && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    minWidth: "px",
                  }}
                >
                  <div
                    style={{
                      fontFamily: "Montserrat",
                      fontSize: "0.7vw",
                      marginRight: "5px",
                      pointerEvents: "none",
                    }}
                  >
                    Client Visibility
                  </div>
                  <label
                    className="switch"
                    style={{
                      pointerEvents: "none",
                      cursor: "default",
                      userSelect: "none",
                    }}
                  >
                    <input
                      type="checkbox"
                      id="toggle-switch"
                      checked={markerVisibility}
                      onChange={handleToggleChange}
                    />
                    <span className="slider1 round"></span>
                  </label>
                </div>
              )}

              <div
                style={{
                  display: "flex",
                  cursor: "default",
                  justifyContent: "space-between",
                  alignItems: "center",
                  minWidth: "0vw",
                }}
              >
                <button
                  id="minus-btn"
                  className="negative-icon"
                  onClick={minusiconclick}
                ></button>
              </div>
            </>
          </div>
        ) : (
          <div
            id="slider"
            style={{
              position: "absolute",
              alignItems:
                editFieldRef === true ||
                editCropType === true ||
                editPlantedDate === true
                  ? "space-between"
                  : "center",
              backgroundColor: "black",
              color: "white",
              borderRadius:
                editFieldRef === true ||
                editCropType === true ||
                editPlantedDate === true
                  ? "25px 25px 10px 10px"
                  : "25px",
              zIndex: "999",
              height:
                editFieldRef === true ||
                editCropType === true ||
                editPlantedDate === true
                  ? "90px"
                  : "32px",
              minWidth: width >= 1300 ? "400px" : "350px",
              width:'max-content'
            }}
          >
            <div
              className="slide-in"
              style={{
                position: "absolute",
                display: "flex",
                flexDirection: "row",
                // justifyContent: "space-between",
                alignItems: "center",
                // minWidth: "600px",
                minWidth: width >= 1300 ? "400px" : "350px",
                // minWidth: "fit-content",
                width: "max-content",
                backgroundColor: "black",
                color: "white",
                padding: "4px",
                borderRadius: "25px",
                zIndex: "999",
                height: "32px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flex: "none",
                  // border:"solid white 2px",
                  marginLeft: "6px",
                  marginRight:"6px",
                  padding: "4px 0px 4px 4px",
                }}
              >
                <div
                  id="drag-icon"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingRight: "16px",
                    // flexGrow: 0,
                    // flexShrink: 0,
                    flex: "none",
                  }}
                >
                  <div
                    id="drag-menu"
                    className="drag-icon"
                    onMouseDown={handleComponentMouseDown}
                    onMouseUp={handleComponentMouseUp}
                  ></div>
                </div>
                <div
                  style={{
                    display: "flex",
                    cursor: "default",
                    justifyContent: "start",
                    // flexGrow: 0,
                    // flexShrink: 0,
                    // minWidth: "1vw",
                    flex: "none",
                    minWidth: "1px",
                  }}
                >
                  <button
                    id="plus-btn"
                    className="plus-icon"
                    onClick={plusiconclick}
                  ></button>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: "none",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin: "0 12px 0 16px",
                }}
              >
                <div className={thumbnailClass}></div>
              </div>

              <div
                style={{
                  display: "flex",
                  flex: "none",
                  justifyContent: "space-between",
                  alignItems: "center",
                  // minWidth: "0.5vw",
                  fontFamily: "Montserrat-Bold",
                  fontSize: "0.7vw",
                  pointerEvents: "none",
                }}
              >
                <div
                  style={{
                    pointerEvents: role === ROLES.ADMIN ? "auto" : "none",
                    cursor: "default",
                    marginLeft: "5px",
                  }}
                  onClick={() => {
                    if (role === ROLES.ADMIN  && overrideData?.length <= 1) {
                      setEditCropType(true);
                      setEditFieldref(false);
                      setEditPlantedDate(false);
                      setFormErrors(undefined)
                      console.log("clicked");
                    }
                  }}
                >
                  {overrideData?.length > 1 ? (
                    <div style={{ marginLeft: "10px", marginRight: "10px" }}>
                      {overrideData?.length}
                    </div>
                  ) : clickedMarker?.cropType ? (
                    <div style={{ marginLeft: "10px", marginRight: "10px" }}>
                      {clickedMarker?.cropType}
                    </div>
                  ) : (
                    <div style={{ marginLeft: "10px", marginRight: "10px" }}>
                      N/A
                    </div>
                  )}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  minWidth: "1px",
                  pointerEvents: "none",
                  marginLeft: "5px",
                  marginRight: "5px",
                }}
              >
                {/* <div className="calender-icon-bar"></div>

                <div
                  style={{
                    fontFamily: "Montserrat-Bold",
                    fontSize: "0.7vw",
                    pointerEvents: role === ROLES.ADMIN ? "auto" : "none",
                    marginLeft: "5px",
                    marginRight: "5px",
                  }}
                  onClick={() => {
                    if (role === ROLES.ADMIN) {
                      setEditPlantedDate(true);
                      setEditFieldref(false);
                      setEditCropType(false);
                      setFormErrors(undefined)
                    }
                  }}
                >
                  {markerData?.plantedDate
                    ? moment(markerData?.plantedDate).format("YYYY-MM-DD")
                    : "N/A"}
                </div> */}
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  // minWidth: "1px",
                  minWidth: "fit-content",
                  pointerEvents: "none",
                  marginLeft: "5px",
                  marginRight: "5px",
                }}
              >
                <div
                  style={{
                    pointerEvents: "auto",
                    cursor: "default",
                    marginRight: "5px",
                  }}
                  className="mobile-icon-bar"
                ></div>

                <div
                  style={{
                    marginLeft: "5px",
                    marginRight: "5px",
                    fontFamily: "Montserrat",
                    fontSize: "0.7vw",
                    pointerEvents: "none",
                  }}
                >
                  {moment(markerData?.dateTime).format("YYYY-MM-DD")}
                </div>
              </div>
              {role === ROLES.ADMIN && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    minWidth: "fit-content",
                    marginLeft: "5px",
                    marginRight: "5px",
                  }}
                >
                  <div
                    style={{
                      // marginLeft: "5px",
                      marginRight: "5px",
                      fontFamily: "Montserrat",
                      pointerEvents: "none",
                      fontSize: "0.7vw",
                      overflow: "hidden",
                    }}
                  >
                    Client Visibility
                  </div>
                  <label
                    className="switch"
                    style={{
                      pointerEvents: "auto",
                      cursor: "default",
                      userSelect: "none",
                      marginLeft: "5px",
                      marginRight: "5px",
                    }}
                  >
                    <input
                      type="checkbox"
                      id="toggle-switch"
                      checked={markerVisibility}
                      onChange={(e)=>{
                        handleToggleChange(e)
                        const {checked}=e.target
                        console.log(currentMarkers)
                        if(currentMarkers===""){
                          markerData.clientVisibility=checked
                          setClickedMarker({...markerData})
                        }
                      }
                        }
                    />
                    <span className="slider1 round"></span>
                  </label>
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontFamily: "Montserrat",
                  minWidth:"fit-content",
                  fontSize: "0.7vw",
                  marginLeft: "5px",
                  marginRight: "5px",
                }}
              >
                {markerData?.fieldReference ? (
                  <div
                    style={{
                      pointerEvents: role === ROLES.ADMIN ? "auto" : "none",
                      cursor: "default",
                    }}
                    onClick={(e) => {
                      if (role === ROLES.ADMIN && overrideData.length >= 1) {
                        e.preventDefault();
                        setEditFieldref(true);
                        setEditCropType(false);
                        setEditPlantedDate(false);
                        setFormErrors(undefined);
                        console.log("clicked");
                      }
                    }}
                  >
                    {markerData?.fieldReference}
                  </div>
                ) : (
                  <div
                    style={{
                      pointerEvents:
                        role === ROLES.ADMIN 
                          ? "auto"
                          : "none",
                      cursor: "default",
                    }}
                    onClick={() => {
                      if(overrideData.length <= 1){
                      setEditFieldref(true);
                      setEditCropType(false);
                      setEditPlantedDate(false);
                      setFormErrors(undefined);
                      console.log("clicked");
                    }
                    }}
                  >
                    {overrideData?.length > 1
                      ? "Cluster"
                      : clickedMarker?.fieldReference
                      ? clickedMarker?.fieldReference
                      : "N/A"}
                  </div>
                )}
              </div>

              
            </div>
            {(editFieldRef === true ||
              editCropType === true ||
              editPlantedDate === true) && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginLeft: "5px",
                  marginTop: "8px",
                  paddingTop: "22px",
                  // height: "30px",
                  // borderRadius: "30px",
                  // marginRight: "0.5vw",
                  // marginLeft: "1vw",
                  // paddingTop: "4px",
                  // marginTop: "1vh",
                }}
              >
                <form  onSubmit={(e)=>{
                        e.preventDefault();

                      }}>
                  {editPlantedDate === true &&
                  editFieldRef === false &&
                  editCropType === false ? (
                    <div>
                    <Input
                   
                      style={{
                        backgroundColor: "black",
                        color: "white",
                        border: "2px solid white",
                        borderRadius: "4px",
                        width: width >= 1300 ? "250%" : "250%",
                        paddingLeft: "10px",
                        marginLeft: "10px",
                        fontFamily: "Montserrat",
                        fontSize: "0.7vw",
                        // marginTop:"10px"
                      }}
                      InputLabelProps={{
                        style: {
                          marginLeft: "10px",
                          fontFamily: "Montserrat",
                          fontSize: "0.7vw",
                        },
                      }}
                      placeholder="New Field Value"
                      type="date"
                      value={newPlantedDate}
                      onChange={(e) => {
                        setnewPlantedDate(
                          moment(e.target.value).format("YYYY-MM-DD")
                        );
                        setFormErrors(validateDate( moment(e.target.value).format("YYYY-MM-DD")))
                      }}
                    ></Input>
                    
                   </div>

                  ) : (
                    <div>
                      <Input
                      style={{
                        backgroundColor: "black",
                        color: "white",
                        border: "2px solid white",
                        borderRadius: "4px",
                        // minWidth: "30px",
                        width: width >= 1300 ? "150%" : "150%",
                        paddingLeft: "10px",
                        marginLeft: "10px",
                        fontFamily: "Montserrat",
                        fontSize: "0.7vw",
                      }}
                      InputLabelProps={{
                        style: {
                          marginLeft: "10px",
                          fontFamily: "Montserrat",
                          fontSize: "0.7vw",
                        },
                      }}
                      placeholder="New Field Value"
                      type="text"
                      value={editFieldRef?  newFieldRef :newCropType}
                      onChange={(e) => {
                        if (editFieldRef === true) {
                          console.log("editFieldRef");

                          setnewFieldRef(e.target.value);
                          setFormErrors(validate(e.target.value))
                        } else if (editCropType === true) {
                          console.log("editCropType");

                          setnewCropType(e.target.value);
                          setFormErrors(validate(e.target.value))

                        }
                      }}
                    ></Input>
                     
                    </div>
                    
                  )}
                </form>
                <Button
                  onClick={handleClose}
                  style={{
                    backgroundColor: "black",
                    color: "white",
                    border: "2px solid white",
                    margin: newPlantedDate? "6px 6px 6px 10vw":"6px 6px 6px 280px",
                    fontFamily: "Montserrat",
                    fontSize: "0.5vw",
                  }}
                >
                  Close
                </Button>
                <Button
                  style={{
                    backgroundColor: "black",
                    color: "white",
                    border: "2px solid white",
                    margin: "6px",
                    marginRight: "17px",
                    fontFamily: "Montserrat",
                    fontSize: "0.5vw",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    console.log(formErrors)
                    console.log("submitted", newFieldRef, markerData?.id);
                    
                      
                        if(editPlantedDate===true){
                          markerData.plantedDate=newPlantedDate
                          handleUpdate(
                            markerData?.plantedDate,
                            newPlantedDate,
                            "plantedDate",
                            "plantedDateArray",
                             validateDate(newPlantedDate)

                          )

                        }
                        else if(editCropType===true){
                          markerData.cropType=newCropType
                          handleUpdate(
                            markerData?.cropType,
                            newCropType,
                            "cropType",
                            "cropTypeArray",
                            validate(newCropType)
                          )

                        }
                        else if(editFieldRef===true){
                          markerData.fieldReference=newFieldRef
                          handleUpdate(
                            markerData?.fieldReference,
                            newFieldRef,
                            "fieldReference",
                            "fieldReferenceArray",
                            validate(newFieldRef)
                          )

                        }
                    setClickedMarker({...markerData})
                    if(formErrors===""){
                      handleClose();

                    }
                    console.log(formErrors!==undefined)
                    console.log(formErrors,formErrors==="")
                    
                  console.log(document
                    ?.getElementById(markerData?.id)
                    ?.classList.remove("yellow-marker-selected"))
                    console.log(document
                      ?.getElementById(markerData?.id)
                      ?.classList)
                      console.log(document
                        ?.getElementById(markerData?.id)
                        )
                      // setCurrentElement(undefined)
                      setIsFormEdited(true)
                    
                  }}
                  disabled={formErrors!==""}
                  autoFocus
                  
                >
                  Save
                </Button>
              </div>
            )}
            {formErrors!==undefined && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          paddingLeft: "13px",
                        }}
                      >
                        <p className="text-danger">{formErrors}</p>
                      </div>
                    )}
          </div>
        )}
      </div>
      {/* {(editFieldRef || editCropType || editPlantedDate) && (
        <EditDialog
          open={open}
          setOpen={setOpen}
          setnewFieldRef={setnewFieldRef}
          editFieldRef={editFieldRef}
          setEditFieldref={setEditFieldref}
          newFieldRef={newFieldRef}
          markerData={markerData}
          setEditCropType={setEditCropType}
          editCropType={editCropType}
          setnewCropType={setnewCropType}
          newCropType={newCropType}
          setEditPlantedDate={setEditCropType}
          setnewPlantedDate={setnewPlantedDate}
          editPlantedDate={editPlantedDate}
          newPlantedDate={newPlantedDate}
        ></EditDialog>
      )} */}
    </>
  );
};

export default TooltipBar;
