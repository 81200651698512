import { firestore } from "configs/firebase";
import { doc, updateDoc } from "firebase/firestore";


export const updateMarkerData=async (id,updateData)=>{
    const docRef = doc(firestore,"capturedImagesDetails", id);
   try{
    await updateDoc(docRef,updateData);
   }catch(err){
    console.log("ERROR UPDATE:",err);
    throw new Error(err);
   }
}
