import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./Slices/AuthSlice";
import userReducer from "./Slices/UserSlice";
import imageReducer from "./Slices/ImageSlice";

export default combineReducers({
  // mapReducer,
  userReducer,
  authReducer,
  imageReducer,
});
