import * as React from "react";
import "./OverlaySideBar.css";
const OverlaySideBar = (props) => {
  return (
    <div className="overlay-side-bar">
      <div className="overlay-content">
        <div className="row-spacing-icon"></div>
        <h6
          style={{
            fontSize: "13px",
            marginLeft: "15px",
            fontFamily: "Montserrat",
          }}
        >
          {props.clickedMarker?.rowSpacing
            ? props.clickedMarker?.rowSpacing
            : "VWIDE"}
        </h6>
      </div>
      <div className="overlay-content">
        <div className="plant-spacing-icon"></div>
        <h6
          style={{
            fontSize: "13px",
            marginLeft: "15px",
            fontFamily: "Montserrat",
          }}
        >
          {props.clickedMarker?.plantSpacing
            ? props.clickedMarker?.plantSpacing
            : "ASSDFGHJJKL"}
        </h6>
      </div>
      <div className="overlay-content">
        <div className="eveness-icon"></div>
        <h6
          style={{
            fontSize: "13px",
            marginLeft: "15px",
            fontFamily: "Montserrat",
          }}
        >
          {props.clickedMarker?.howEven ? props.clickedMarker?.howEven : "VSDK"}
        </h6>
      </div>
      <div className="overlay-content">
        <h6 style={{ fontSize: "13px", fontFamily: "Montserrat" }}>
          <mark className="marked-content">
            {props.clickedMarker?.fieldReference
              ? props.clickedMarker?.fieldReference
              : "N/A"}
          </mark>
        </h6>
      </div>
    </div>
  );
};
export default OverlaySideBar;
