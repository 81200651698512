import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  images: [],
};

const ImageSlice = createSlice({
  name: "image",
  initialState,
  reducers: {
    addImage: (state, action) => {
      console.log("addImage from Image Slice file");
      state.images.push(action.payload);
    },
  },
});


export const imageSelector = (state) => state.entity.imageReducer.images;
export const { addImage } = ImageSlice.actions;

export default ImageSlice.reducer;
