import * as React from "react";
import moment from "moment";
import "./Carousel.css";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useState } from "react";
// import ImageGallery from "react-image-gallery";
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { db } from "configs/firebase";

const CarouselComponent = (props) => {
  const [localUrl, setLocalUrl] = useState();
  const storage = getStorage(db);
  const pathReference = ref(
    storage,
    process.env.REACT_APP_FIREBASE_IMAGE_BASEURL +
      `capturedImages/${props.clickedMarker.id}`
  );
  getDownloadURL(pathReference)
    .then((url) => {
      console.log(process.env.REACT_APP_FIREBASE_IMAGE_BASEURL + `capturedImages/${props.clickedMarker.id}`, url)
      setLocalUrl(url);
    }).catch((error)=>{
      setLocalUrl(undefined);
    })
   
  let arr=[];
  if (
    props.clickedMarker?.capturedImageMode === "panorama" &&
    props.clickedMarker?.panoramaImages?.length > 1
  ) {
    if (props.overrideData.length >= 1) {
      props.overrideData?.forEach((element) => {
        if (
          element.capturedImageMode === "panorama" &&
          element.panoramaImages?.length >= 1
        ) {
          arr = [...arr, { src: element.imageRemoteUrl }];
          element.panoramaImages.forEach(
            (item) => (arr = [...arr, { src: item.imageRemoteUrl }])
          );
        } else {
          arr = [...arr, { src: element.imageRemoteUrl }];
        }
      });
    }
  } else {
    arr = [];
    if (props.overrideData.length >= 1) {
      props.overrideData?.forEach((element) => {
        if (
          element.capturedImageMode === "panorama" &&
          element.panoramaImages?.length >= 1
        ) {
          arr = [...arr, { src: element.imageRemoteUrl }];
          element.panoramaImages.forEach(
            (item) => (arr = [...arr, { src: item.imageRemoteUrl }])
          );
        } else {
          arr = [...arr, { src: element.imageRemoteUrl }];
        }
      });
    }
  }
  // ------------------------------------------------
  //  Commmenting Gallery View for Image Overlay
  // const [galleryView, setGalleryView] = useState(null);
  const handleGallery = () => {
    props.setImgGallery(true);
  };

  const closeGallery = () => {
    //setGalleryView(false);
    props.setImgGallery(false);
  };

  // style={{ position: 'relative', width: '256px', height: '244px', marginLeft:'400px', }}
 
  let totalCharsDate = moment(props.clickedMarker?.dateTime)
    .format("YYYY-MM-DD")
    .toUpperCase()?.length;
  let totalCharsImg = props.clickedMarker?.capturedImageMode.length ;
  const angleIncrementDate = 50 / (totalCharsDate - 2);
  const angleIncrementImg = 60 / (totalCharsImg - 2);
  
  let currentAngle = 40;
  let currentAngle_ = -40;
  let thumbnailClassTopBar =
    props?.clickedMarker?.capturedImageMode === "panorama"
      ? "thumbnail-landscape"
      : props?.clickedMarker?.capturedImageMode === "waypoint"
      ? "thumbnail-waypoint"
      : "thumbnail-portrait";

  return (
    <>
      {!props.imgGallery && props.triggerOverlay && (
        <div className="wrap">
          <div
            style={{
              position: "relative",
              width: "33vh",
              height: "33vh",
              borderRadius: "50%",
              backgroundImage:
                props.clickedMarker?.imageRemoteUrl != null
                  ? `url(${props.clickedMarker?.imageRemoteUrl})`
                  : `url(${localUrl})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              pointerEvents: "all",
              // paddingLeft: "3%",
            }}
            onClick={handleGallery}
          >
            {/* <div className="text"><p>Portrait </p></div> */}

            <div className="toptext inversed">
              {props.clickedMarker?.capturedImageMode
                .split("")
                .reverse()
                .map((char, i) => {
                  const style =
                    props.clickedMarker?.capturedImageMode === "panorama"
                      ? {
                          // height: i >= 4  ? "40vh" : "33vh",
                          lineHeight: i >= 5 ? "34.3vh" : "33vh",
                          transform:
                            i === 0
                              ? `rotate(${currentAngle_ + 3}deg)`
                              : `rotate(${currentAngle_}deg)`,
                          transformOrigin: `0 ${34}vh`,
                        }
                      : {
                          // height: i >= 4  ? "40vh" : "33vh",
                          lineHeight: i >= 5 ? "34.3vh" : "33vh",
                          transform: `rotate(${currentAngle_}deg)`,
                          transformOrigin: `0 ${34}vh`,
                        };

                  currentAngle_ -= angleIncrementImg;

                  return (
                    <span key={`heading-span-${i}`} style={style}>
                      {char}
                    </span>
                  );
                })}
              <div className="carousel-control-bar">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginLeft: "10px",
                  }}
                >
                  <div className={thumbnailClassTopBar}></div>
                </div>
                {/* <FullscreenIcon className="carousel-icon"></FullscreenIcon> */}
                {props.clickedMarker?.panoramaImages?.length ? (
                  <h6 className="carousal-bar-text">
                    1/{1 + props.clickedMarker?.panoramaImages?.length}
                  </h6>
                ) : (
                  <h6 className="carousal-bar-text">1/1</h6>
                )}
                <ArrowRightIcon className="carousel-icon"></ArrowRightIcon>
              </div>
            </div>
            {/* <div className="logo"></div> */}
            {/* <div className="text"><p>Portrait </p></div> */}
            <div className="toptext">
              {moment(props.clickedMarker?.dateTime)
                .format("YYYY-MM-DD")
                .toUpperCase()
                ?.split("")
                .map((char, i) => {
                  const style = {
                    // height: "35vh",
                    transform: `rotate(${currentAngle}deg)`,
                    paddingTop: `${totalCharsDate * 0.8}px`,
                  };
                  totalCharsDate--;

                  currentAngle -= angleIncrementDate;

                  return (
                    <span key={`heading-span-${i}`} style={style}>
                      {char}
                    </span>
                  );
                })}
            </div>

            {/* <div
          style={{
            // position: "absolute",
            top: 40,
            left: 50,
            width: "33vh",
            height: "33vh",
            borderRadius: "50%",
          }}
        >
          <img
            src={props.clickedMarker?.imageRemoteUrl }
            style={{
              position: "absolute",
              top: 40,
              left: 50,
              width: "33vh",
              height: "33vh",
              borderRadius: "50%",
            }}
          />
          </div> */}
          {/* <div className="toptext">
        {props.clickedMarker?.capturedImageMode?.split("").map((char, i) => {
          const style = {
            height: "200px",
            transform: `rotate(${currentAngle}deg)`,
          };

          currentAngle -= angleIncrement;

          return (
            <span key={`heading-span-${i}`} style={style}>
              {char}
            </span>
          );
        })}
      </div> */}
        </div>
      </div>)}

      {/* <svg
          style={{
            height: "100%",
            width: "100%",
           
          }}
        >

          <defs>
            <clipPath id="circleView">
              <circle id="curve" cx="40%" cy="56%" r='22%' fill="transparent" />
            </clipPath>
          </defs>
          <path
            id="orientationText"
            d={d}
            stroke=""
            fill="none"
          />
          <path
            id="dateTimeText"
            d="M 270,400 C 270,500  470,500  150,500"
            stroke=""
            fill="none"
          />

          <text className="carousel-curved-text">
            <textPath xlinkHref="#orientationText">
              {props.clickedMarker?.orientation.toUpperCase()}
            </textPath>
            <textPath xlinkHref="#dateTimeText">
              {moment(props.clickedMarker?.dateTime).format("YYYY-MM-DD").toUpperCase()}
            </textPath>
          </text>
          <image
            width="100%"
            height="100%"
            preserveAspectRatio="xMidYMid slice"
            xlinkHref={imgUrl}
            clipPath="url(#circleView)"
          />
        </svg> */}

      {/* {(!props.imgGallery && props.triggerOverlay) && (
        <div className="carousel-wrapper">
          <ReactCurvedText
            width="100%"
            height="70%"
            cx={cx}
            cy={cy}
            rx={circleWidth / 2}
            ry={circleHeight / 2}
            startOffset={100}
            reversed={true}
            text={props.clickedMarker?.capturedImageMode}
            textProps={{
              style: {
                fontSize: "14px",
                fontFamily: "Montserrat",
                fontWeight: "bold",
              },
            }}
            // tspanProps={{ dy: '-15' }}
          />
          <ReactCurvedText
            width="100%"
            height="100%"
            cx={(59 / 100) * widthInPx}
            cy={(-25 / 100) * heightInPx}
            rx={(45 / 100) * widthInPx}
            ry={(55 / 100) * heightInPx}
            startOffset={140}
            text={moment(props.clickedMarker?.dateTime)
              .format("YYYY-MM-DD")
              .toUpperCase()}
            textProps={{
              style: {
                fontSize: "14px",
                fontFamily: "Montserrat",
                fontWeight: "bold",
              },
            }}
            // tspanProps={{ dy: '-15' }}
          />

          <div
            onClick={handleGallery}
            style={{
              position: "absolute",
              top: 40,
              left: 50,
              width: "33vh",
              height: "33vh",
              borderRadius: "50%",
              cursor: "pointer",
            }}
          >
              <img
                // onClick={handleGallery}
                src={props.clickedMarker?.imageRemoteUrl}
                style={{
                  position: "absolute",
                  top: 40,
                  left: 50,
                  width: "33vh",
                  height: "33vh",
                  borderRadius: "50%",
                  cursor: "pointer",
                  pointerEvents: "all",
                }}
              />

            <div
              style={{
                // position: 'absolute',
                top: 65,
                marginLeft: "90%",
              }}
              className="carousel-control-bar "
            >
              <FullscreenIcon className="carousel-icon"></FullscreenIcon>
              <h6 className="carousal-bar-text">1/{arr.length}</h6>
              <ArrowRightIcon className="carousel-icon"></ArrowRightIcon>
            </div>
          </div>
        </div>
      )} */}
      {/* Commenting gallery view for image overlay instead using props */}
      {props.imgGallery && (
       <div
       className="image-gallery-view"
       style={{
         width: "100%",
         position: "sticky",
         zIndex: 14,
         backgroundColor: "black",
       }}
     >
       <div
         style={{
           zIndex: 100001,
           top: "24px",
           position: "relative",
           textAlign: 'end',
           paddingRight: '24px'
         }}
       >
            <a>
              <img
                className="close-icon"
                src="/icons/Property 1=Default.svg"
                alt="close-icon"
                onClick={closeGallery}
                style={{
                  pointerEvents: "all",
                  border: "none",
                  background: "transparent",
                  width: '30px',
                  height: '30px'
                }}
              ></img>
              <img
                className="close-icon"
                src="/icons/Property 1=Hover.svg"
                alt="close-icon"
                onClick={closeGallery}
                style={{
                  pointerEvents: "all",
                  border: "none",
                  background: "transparent",
                  width: '30px',
                  height: '30px'
                }}
              ></img>
            </a>
          </div>
          {/* <img
            className="close-svg"
            src="/icons/Property 1=Default.svg"
            alt="close-icon"
            onClick={closeGallery}
            style={{
              zIndex: 100001,
              pointerEvents: "all",
              top: "3rem",
              position: "relative",
              border: "none",
              background: "transparent",
            }}
          ></img> */}

          <Carousel
            images={arr}
            canAutoPlay={false}
            hasSizeButton={false}
            objectFit="contain"
            // isMaximized={false}
            objectFitAtMax="contain"
            // hasLeftButton={
            //   props.clickedMarker?.capturedImageMode === "panorama" || props.overrideData.length > 1
            //     ? "centerLeft"
            //     : false
            // }
            // hasRightButton={
            //   props.clickedMarker?.capturedImageMode === "panorama" || props.overrideData.length > 1
            //     ? "centerRight"
            //     : false
            // }
            hasLeftButton={false}
            hasRightButton={false}
            hasThumbnails={
              props.clickedMarker?.capturedImageMode === "panorama" ||
              props.overrideData.length > 1
            }
            hasIndexBoard={
              props.clickedMarker?.capturedImageMode === "panorama" ||
              props.overrideData.length > 1
                ? "topLeft"
                : false
            }
            style={{
              top: "-2rem",
              height: "100vh",
            }}
          />
        </div>
      )}
    </>
  );
};
export default CarouselComponent;
