import { doc, getDoc, query, updateDoc } from "firebase/firestore";
import { firestore } from "configs/firebase";
import { arrayUnion } from "firebase/firestore";

export const updateCropData = async (id, fieldref, newFieldRef,field,fieldArr) => {
  console.log("here");
  const docRef = doc(firestore, "capturedImagesDetails", id);
  const fieldArray = [fieldref];
  console.log(newFieldRef)
  const data = {
    [fieldArr]: fieldArray,
  };
  const editExistingData = {
    [field]: newFieldRef,
  };
  let docQuery, documentSnapshot;
  docQuery = query(docRef);
  documentSnapshot = await getDoc(docQuery);
  console.log(documentSnapshot.data());
  async function updateArray() {
  
    if (documentSnapshot.exists) {
      
      if (documentSnapshot.data().hasOwnProperty(fieldArr)) {
        console.log("true case");
        console.log(documentSnapshot.data().hasOwnProperty(fieldArr));
        console.log(fieldref)
        console.log(fieldArr)
        await updateDoc(docRef, {
            
          [fieldArr]: arrayUnion(newFieldRef),
        });
      } else {
        console.log("false case");
        updateDoc(docRef, data)
          .then(() => {
            console.log("New Doc field added");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
// implementaion for a separate record of original values
    // if (documentSnapshot.exists) {
    //   if (!documentSnapshot.data().hasOwnProperty("fieldReferenceOriginal")) {
    //     console.log("case where croptype will be added and original will be kept in it");
    //     console.log(documentSnapshot.data().hasOwnProperty("fieldReferenceOriginal"));
    //     updateDoc(docRef, {
    //       fieldReferenceOriginal: fieldref,
    //     })
    //     .then(() => {
    //       console.log("Original record added");
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
        
    //   }
    // }
  }
  async function updateField() {
    await updateArray();
    updateDoc(docRef, editExistingData)
      .then(() => {
        console.log("Doc field edited");
      })
      .catch((error) => {
        console.log(error);
      });
  }
  updateField();
};
